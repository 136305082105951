@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Righteous&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #01bb94;
  --secondary-color: #019676;
  --light-bg-color: #21272F;
  --dark-bg-color: #171C23;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  background-color: var(--light-bg-color);
  color: #f3f3f3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Righteous', cursive;
  letter-spacing: 2px;
  color: #fff;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

p {
  line-height: 1.7;
  margin-bottom: 1.5rem;
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Row */
.row {
  display: flex;
  gap: 40px;
}

/* Padding */
.py {
  padding: 80px 0px;
}

/* Large text */
.lg-text {
  font-size: 24px;
  margin-bottom: 1rem;
  font-weight: bold;
}

/* Primary Button */
.btn-primary {
  padding: 15px 24px;
  font-family: 'Poppins', arial;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 16px rgba(106, 170, 146, 0.20));
  transition: 0.4s;
}

.btn-primary:hover {
  background-color: var(--secondary-color);
}

/* Primary Text Color */
.text-primary {
  color: var(--primary-color);
}

/* heading */
.h-txt {
  font-size: 36px;
  text-align: center;
  margin-bottom: 2.3rem;
}