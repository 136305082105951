.my-projects {
  background-color: var(--dark-bg-color);
}

.projects-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  gap: 10px;
  margin-bottom: 2rem;
}

.projects-filter li {
  padding: 5px 10px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  font-size: 14px;
  transition: 0.4s;
}

.projects-filter li:hover {
  background-color: var(--primary-color);
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  transition: 0.5s;
}

.project {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #141125;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.project .project-content {
  padding: 1.5rem;
}

.project .project-img img {
  width: 100%;
  height: 230px;
}

.project h3 {
  font-size: 20px;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.project p {
  font-size: 14px;
  margin-bottom: 0;
}

.project .btn-group {
  position: relative;
  display: inline-flex;
  width: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
}

.project .btn-group .project-btn {
  background: var(--primary-color);
  padding: 10px 15px;
  color: #fff;
  flex: 1 1 auto;
  text-align: center;
  border-right: 1px solid #333;
  transition: 0.4s;
}

.project .btn-group .project-btn:hover {
  background: var(--secondary-color);
}

.project .btn-group .project-btn:last-child {
  border: none
}


@media screen and (max-width: 922px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 630px) {
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .project-img img {
    height: auto;
  }
}