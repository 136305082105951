.contact-container {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem
}

.my-address {
  flex: 1;
}

.my-address h3,
.email-me h3 {
  font-size: 25px;
  margin-bottom: 1.5rem;
}

.my-address ul {
  list-style-type: none;
}

.my-address ul li {
  margin-bottom: 1.5rem;
}

.my-address ul li>i {
  font-size: 20px;
  background-color: var(--dark-bg-color);
  padding: 1.5rem;
  border-radius: 10px;
  margin-right: 1rem;
}

.my-address p {
  margin-bottom: 1rem;
}

.email-me {
  flex: 1;
}

.email-me input,
textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 15px 10px;
  margin-bottom: 1.5rem;
  background-color: transparent;
  color: #fff;
  border: 1px solid #333;
  font-family: 'Poppins', sans-serif;
}

.email-me input::placeholder,
textarea::placeholder {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #fff;
}

.email-me textarea {
  height: 150px;
}

@media screen and (max-width: 630px) {
  .contact-container {
    flex-direction: column;
  }
}