.skills-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: 1.5rem;
}

.skill {
  background: var(--dark-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 5px;
  box-sizing: border-box;
  transition: 0.4s;
}

.skill img {
  width: 70px;
}

.skill p {
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
}

.skill:hover {
  background: var(--primary-color);
}